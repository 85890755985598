import { useMemo, useCallback } from "react";
import * as R from "ramda";
import TextField from "@mui/material/TextField";
import { textChangeHandler } from "../utils/eventUtils";
import { toRawCnic, toFormattedCnic } from "../domain/cnicUtils";

// text field for entering a CNIC. We can also expand this to allow
// other types of IDs to be entered.

interface PatientIdFieldProps {
  patientId: string;
  setPatientId: (s: string) => void;
  disabled: boolean;
  required: boolean;
}

function PatientIdField({
  patientId,
  setPatientId,
  disabled,
  required,
}: PatientIdFieldProps) {
  const formattedCnic = useMemo(() => toFormattedCnic(patientId), [patientId]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRawCnic = useCallback(R.compose(setPatientId, toRawCnic), [
    setPatientId,
  ]);

  return (
    <TextField
      required={required}
      inputProps={{ "data-testid": "cnic" }}
      label="CNIC"
      variant="filled"
      value={formattedCnic}
      onChange={textChangeHandler(setRawCnic)}
      disabled={disabled}
    />
  );
}

export default PatientIdField;
