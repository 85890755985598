import { KeyboardEvent } from "react";
import { SelectChangeEvent } from "@mui/material";

type TextHandler = (value: string) => void;
type BoolHandler = (value: boolean) => void;

export function enterKeyHandler(callback: TextHandler) {
  return (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // it looks like material doesn't have its types straight.
      callback((e.target as HTMLInputElement).value);
    }
  };
}

export function textChangeHandler(callback: TextHandler) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    callback(e.target.value);
  };
}

export function textareaChangeHandler(callback: TextHandler) {
  return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    callback(e.target.value);
  };
}

export function checkboxChangeHandler(callback: BoolHandler) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    callback(e.target.checked);
  };
}

export function selectHandler(ev: SelectChangeEvent) {
  return ev?.target?.value;
}

export function multiSelectHandler(ev: SelectChangeEvent<string[]>) {
  return ev?.target?.value;
}
