import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HeartIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { nullaryBool } from "shared-utils";
import {
  type Visit,
  type Vitals,
  type VisitInput,
  type VitalsInput,
} from "../../graphql/generated";
import VitalsRoute from "../vitals/VitalsRoute";
import { useCreateVisitMutation } from "../../hooks/apiHooks/visitMutationHooks";
import { useServiceBag } from "../../services/ServiceBag";
import { type RootState } from "../../state/reducers";
import { setVisit } from "../../state/visitSlice";
import VitalsDialog from "./VitalsDialog";

export default function CreateUserRoute() {
  const { createVisit } = useCreateVisitMutation();
  const { envService } = useServiceBag();
  const { visit, vitals } = useSelector((state: RootState) => state.visit);
  const dispatch = useDispatch();
  const [showingCreateDialog, setShowingCreateDialog] = useState(false);
  const [showDialog, hideDialog] = nullaryBool(setShowingCreateDialog);

  const onCreateVisit = (
    patientId: string,
    height: number,
    weight: number,
    systolicBloodPressure: number,
    diastolicBloodPressure: number,
    heartRate: number,
    respiratoryRate: number,
    bloodOxygen: number,
    temperature: number
  ) => {
    const vitalsInfo: VitalsInput = {
      height,
      weight,
      systolicBloodPressure,
      diastolicBloodPressure,
      heartRate,
      respiratoryRate,
      bloodOxygen,
      temperature,
    };
    const visitInfo: VisitInput = {};
    return createVisit({
      patientId,
      patientIdType: "PKCNI",
      visitId: "VISITTEST",
      createdAt: envService.currentTime(),
      visit: visitInfo,
      vitals: vitalsInfo,
    })
      .then((result) => {
        dispatch(setVisit(result));
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };
  return (
    <>
      <CreateVisitUiView
        visit={visit}
        vitals={vitals}
        showCreateDialog={showDialog}
      />
      <VitalsDialog
        open={showingCreateDialog}
        requestClose={hideDialog}
        onCreateVitals={onCreateVisit}
      />
    </>
  );
}

interface CreateVisitUiViewParams {
  visit?: Visit;
  vitals?: Vitals;
  showCreateDialog: () => void;
}

function CreateVisitUiView({
  visit,
  vitals,
  showCreateDialog,
}: CreateVisitUiViewParams) {
  if (!visit || !vitals) {
    return <Button onClick={showCreateDialog}>Start a visit</Button>;
  }
  return <VisitUiView visit={visit} vitals={vitals} />;
}

interface VisitUiViewParams {
  visit: Visit;
  vitals: Vitals;
}

function VisitUiView({ visit, vitals }: VisitUiViewParams) {
  const intl = useIntl();

  const bloodO2Text = `${vitals.bloodOxygen || ""}%`;
  const bloodPressureText = `${vitals.systolicBloodPressure || ""}/${
    vitals.diastolicBloodPressure || ""
  }`;
  return (
    <Stack spacing="1em">
      <Stack direction="row" spacing="1em">
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.height" })}
          value={vitals.height || ""}
          unit={intl.formatMessage({ id: "vitals.height-unit" })}
          showIcon={false}
        />
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.weight" })}
          value={vitals.weight || ""}
          unit={intl.formatMessage({ id: "vitals.weight-unit" })}
          showIcon={false}
        />
      </Stack>
      <Stack direction="row" spacing="1em">
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.temperature" })}
          value={vitals.temperature || ""}
          unit={intl.formatMessage({ id: "vitals.temperature-unit" })}
          showIcon={true}
        />
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.blood-pressure" })}
          value={bloodPressureText}
          unit={intl.formatMessage({ id: "vitals.blood-pressure-unit" })}
          showIcon={true}
        />
      </Stack>
      <Stack direction="row" spacing="1em">
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.heartRate" })}
          value={vitals.heartRate || ""}
          unit={intl.formatMessage({ id: "vitals.heartRate-unit" })}
          showIcon={true}
        />
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.respiratoryRate" })}
          value={vitals.respiratoryRate || ""}
          unit={intl.formatMessage({ id: "vitals.respiratoryRate-unit" })}
          showIcon={true}
        />
        <VisitInfoPanel
          title={intl.formatMessage({ id: "vitals.bloodOxygen-short" })}
          value={bloodO2Text}
          unit={intl.formatMessage({ id: "vitals.bloodOxygen-unit" })}
          showIcon={true}
        />
      </Stack>
      <Box height="2em" />
      <VitalsRoute />
    </Stack>
  );
}

interface VisitInfoPanelParams {
  title: string;
  value: string | number;
  unit: string;
  showIcon: boolean;
}
function VisitInfoPanel({
  title,
  value,
  unit,
  showIcon,
}: VisitInfoPanelParams) {
  return (
    <Card sx={{ width: "12em" }}>
      <Stack alignItems="flex-start">
        <Typography variant="subtitle2">{title}</Typography>
        <Stack
          direction="row"
          alignContent="flex-end"
          alignItems="flex-end"
          spacing="5px"
        >
          {showIcon && <VisitInfoIcon />}
          <Typography variant="h4">{value}</Typography>
          <Typography variant="caption">{unit}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

function VisitInfoIcon() {
  const theme = useTheme();
  return (
    <Box
      borderRadius="50%"
      sx={{ backgroundColor: theme.palette.primaryContainer.main }}
      width="1.5em"
      height="1.5em"
      justifyContent="center"
      display="flex"
      alignItems="center"
      marginBottom="10px"
    >
      <HeartIcon fontSize="small" />
    </Box>
  );
}
