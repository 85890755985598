import { useMemo, useState } from "react";
import { useServiceBag } from "../services/ServiceBag";
import { type DataCallback, type FailureCallback } from "../services/recorder";

function useRecorder(onDataReceived: DataCallback, onFailure: FailureCallback) {
  const { envService } = useServiceBag();
  const [recordingInProgress, setRecordingInProgress] = useState(false);

  const recorder = useMemo(
    () =>
      envService.recorder(setRecordingInProgress, onDataReceived, onFailure),
    [envService, onFailure, onDataReceived]
  );

  return { recorder, recordingInProgress };
}

export default useRecorder;
