import { createTheme } from "@mui/material/styles";

const primaryMain = "#85CFFF";
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: "#56DF87",
    },
    error: {
      main: "#FFB4A6",
    },
    secondaryContainer: {
      main: "#005228",
    },
    primaryContainer: {
      main: "#004C6C",
    },
  },
});

theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "#004C6C",
        color: "#000",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        backgroundColor: "#bbbbbb",
        color: "#000000",
        textAlign: "left",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        backgroundColor: "#222222",
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        backgroundColor: "#222222",
      },
    },
  },
};

export default theme;
