import { api } from './baseApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type LabDisplay = {
  actualDate?: InputMaybe<Scalars['String']>;
  doctorNote?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  labId: Scalars['String'];
  labInformation?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  needRead: Scalars['Boolean'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  test: Scalars['String'];
};

export type LabOutput = {
  __typename?: 'LabOutput';
  actualDate?: Maybe<Scalars['Date']>;
  doctorNote?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  labId: Scalars['String'];
  labInformation?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  needRead: Scalars['Boolean'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  test: Scalars['String'];
};

export type Medication = {
  __typename?: 'Medication';
  dosage: Scalars['String'];
  medicationId: Scalars['String'];
  name: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  schedule?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status: Scalars['String'];
};

export type MedicationCreation = {
  dosage: Scalars['String'];
  medicationId: Scalars['String'];
  name: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  schedule?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  status?: InputMaybe<Scalars['String']>;
};

export type MedicationDay = {
  __typename?: 'MedicationDay';
  completions: Array<Maybe<MedicationDayCompletion>>;
  day: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type MedicationDayCompletion = {
  __typename?: 'MedicationDayCompletion';
  medicationId: Scalars['String'];
  scheduledTime: Scalars['Int'];
  takenAt: Scalars['String'];
};

export type MedicationDayCompletionInput = {
  medicationId: Scalars['String'];
  scheduledTime: Scalars['Int'];
  takenAt: Scalars['String'];
};

export type MedicationDayCompletionUpdate = {
  medicationId: Scalars['String'];
  scheduledTime: Scalars['Int'];
  takenAt?: InputMaybe<Scalars['String']>;
};

export type MedicationDayCreation = {
  completions?: InputMaybe<Array<InputMaybe<MedicationDayCompletionInput>>>;
  day: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type MedicationDayUpdate = {
  completionUpdates: Array<InputMaybe<MedicationDayCompletionUpdate>>;
  day: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addMedicationPhoto?: Maybe<Scalars['String']>;
  createLab?: Maybe<LabOutput>;
  createMedication?: Maybe<Medication>;
  createMedicationDay?: Maybe<MedicationDay>;
  createPatient?: Maybe<Patient>;
  createRecording?: Maybe<Recording>;
  createSiteNote?: Maybe<SiteNote>;
  createVisit?: Maybe<Visit>;
  createVitals?: Maybe<Vitals>;
  createWebUser?: Maybe<WebUser>;
  deleteRecording?: Maybe<Scalars['Boolean']>;
  login?: Maybe<WebUser>;
  logout?: Maybe<Scalars['Boolean']>;
  patientLogin?: Maybe<Patient>;
  updateMedicationDay: Array<Maybe<MedicationDay>>;
  updatePatient?: Maybe<Patient>;
  updateRecordingNotes?: Maybe<Recording>;
  updateRecordingStatus?: Maybe<Recording>;
};


export type MutationAddMedicationPhotoArgs = {
  patient: PatientId;
  photoId: PhotoId;
};


export type MutationCreateLabArgs = {
  lab: LabDisplay;
};


export type MutationCreateMedicationArgs = {
  medication: MedicationCreation;
};


export type MutationCreateMedicationDayArgs = {
  medicationDay: MedicationDayCreation;
};


export type MutationCreatePatientArgs = {
  patient: PatientCreation;
};


export type MutationCreateRecordingArgs = {
  recording: RecordingInput;
};


export type MutationCreateSiteNoteArgs = {
  note: SiteNoteInput;
};


export type MutationCreateVisitArgs = {
  createdAt: Scalars['Date'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  visit: VisitInput;
  visitId: Scalars['String'];
};


export type MutationCreateVitalsArgs = {
  createdAt: Scalars['Date'];
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  vitals: VitalsInput;
};


export type MutationCreateWebUserArgs = {
  webUser: WebUserInput;
};


export type MutationDeleteRecordingArgs = {
  recording: RecordingKeys;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationPatientLoginArgs = {
  password: Scalars['String'];
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};


export type MutationUpdateMedicationDayArgs = {
  completionUpdates: Array<InputMaybe<MedicationDayUpdate>>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};


export type MutationUpdatePatientArgs = {
  patient: PatientUpdate;
};


export type MutationUpdateRecordingNotesArgs = {
  recording: RecordingKeys;
  updates: RecordingNotesUpdate;
};


export type MutationUpdateRecordingStatusArgs = {
  recording: RecordingKeys;
  status: RecordingStatusUpdate;
};

export type Patient = {
  __typename?: 'Patient';
  allergies?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  labs?: Maybe<Array<Maybe<LabOutput>>>;
  lastName?: Maybe<Scalars['String']>;
  medicationDays?: Maybe<Array<Maybe<MedicationDay>>>;
  medications?: Maybe<Array<Maybe<Medication>>>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  photoDownloadUrl?: Maybe<Scalars['String']>;
  photoUploadUrl?: Maybe<Scalars['String']>;
};

export type PatientCreation = {
  allergies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type PatientId = {
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type PatientUpdate = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
};

export type PhotoId = {
  photoId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  patient?: Maybe<Patient>;
  recording: Recording;
  recordings: Array<Maybe<Recording>>;
  recordingsById: Array<Maybe<Recording>>;
  siteNotes: Array<Maybe<SiteNote>>;
  siteNotesById: Array<Maybe<SiteNote>>;
  userForSession?: Maybe<WebUser>;
  users?: Maybe<Array<Maybe<User>>>;
  usersForRole?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryPatientArgs = {
  id: Scalars['String'];
  patientIdType: Scalars['String'];
};


export type QueryRecordingArgs = {
  id: Scalars['String'];
};


export type QueryRecordingsArgs = {
  criteria: RecordingQueryInput;
};


export type QueryRecordingsByIdArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};


export type QuerySiteNotesArgs = {
  criteria: SiteNoteQueryInput;
};


export type QuerySiteNotesByIdArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};


export type QueryUsersForRoleArgs = {
  role: Scalars['String'];
};

export type Recording = {
  __typename?: 'Recording';
  altTranscription?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['String']>;
  correctedTranscription?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  originalTranscription?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientIdType?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['Date']>;
  recordingId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type RecordingInput = {
  patientId?: InputMaybe<Scalars['String']>;
  patientIdType?: InputMaybe<Scalars['String']>;
  recordedAt: Scalars['Date'];
  recordingId: Scalars['String'];
  user: Scalars['String'];
};

export type RecordingKeys = {
  recordingId: Scalars['String'];
  user: Scalars['String'];
};

export type RecordingNotesUpdate = {
  altTranscription?: InputMaybe<Scalars['String']>;
  correctedTranscription?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  originalTranscription?: InputMaybe<Scalars['String']>;
};

export type RecordingQueryInput = {
  assignedTo?: InputMaybe<Scalars['String']>;
  recordedBy?: InputMaybe<Scalars['String']>;
  since?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RecordingStatusUpdate = {
  assignedTo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SiteNote = {
  __typename?: 'SiteNote';
  createdAt?: Maybe<Scalars['Date']>;
  downloadUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  noteId?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type SiteNoteInput = {
  createdAt: Scalars['Date'];
  filename: Scalars['String'];
  noteId: Scalars['String'];
  user: Scalars['String'];
};

export type SiteNoteQueryInput = {
  since?: InputMaybe<Scalars['Date']>;
};

export type User = {
  __typename?: 'User';
  age?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Visit = {
  __typename?: 'Visit';
  chiefComplaint?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  visitId: Scalars['String'];
};

export type VisitInput = {
  chiefComplaint?: InputMaybe<Scalars['String']>;
};

export type Vitals = {
  __typename?: 'Vitals';
  bloodOxygen?: Maybe<Scalars['Int']>;
  diastolicBloodPressure?: Maybe<Scalars['Int']>;
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  heartRate?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  respiratoryRate?: Maybe<Scalars['Int']>;
  systolicBloodPressure?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Int']>;
};

export type VitalsInput = {
  bloodOxygen?: InputMaybe<Scalars['Int']>;
  diastolicBloodPressure?: InputMaybe<Scalars['Int']>;
  heartRate?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  respiratoryRate?: InputMaybe<Scalars['Int']>;
  systolicBloodPressure?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type WebUser = {
  __typename?: 'WebUser';
  organization?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  username: Scalars['String'];
};

export type WebUserInput = {
  organization?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CreatePatientMutationVariables = Exact<{
  patient: PatientCreation;
}>;


export type CreatePatientMutation = { __typename?: 'Mutation', createPatient?: { __typename?: 'Patient', patientId: string, patientIdType: string } | null };

export type CreateRecordingMutationVariables = Exact<{
  recording: RecordingInput;
}>;


export type CreateRecordingMutation = { __typename?: 'Mutation', createRecording?: { __typename?: 'Recording', uploadUrl?: string | null } | null };

export type CreateSiteNoteMutationVariables = Exact<{
  siteNote: SiteNoteInput;
}>;


export type CreateSiteNoteMutation = { __typename?: 'Mutation', createSiteNote?: { __typename?: 'SiteNote', uploadUrl?: string | null } | null };

export type CreateVisitMutationVariables = Exact<{
  patientId: Scalars['String'];
  patientIdType: Scalars['String'];
  visitId: Scalars['String'];
  createdAt: Scalars['Date'];
  visit: VisitInput;
  vitals: VitalsInput;
}>;


export type CreateVisitMutation = { __typename?: 'Mutation', createVisit?: { __typename?: 'Visit', patientId: string, patientIdType: string, visitId: string, chiefComplaint?: string | null } | null, createVitals?: { __typename?: 'Vitals', patientId: string, patientIdType: string, eventId: string, eventType: string, height?: number | null, weight?: number | null, temperature?: number | null, heartRate?: number | null, respiratoryRate?: number | null, bloodOxygen?: number | null, systolicBloodPressure?: number | null, diastolicBloodPressure?: number | null } | null };

export type CreateWebUserMutationVariables = Exact<{
  webUser: WebUserInput;
}>;


export type CreateWebUserMutation = { __typename?: 'Mutation', createWebUser?: { __typename?: 'WebUser', username: string } | null };

export type DeleteRecordingMutationVariables = Exact<{
  recordingKeys: RecordingKeys;
}>;


export type DeleteRecordingMutation = { __typename?: 'Mutation', deleteRecording?: boolean | null };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'WebUser', username: string, role: string } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: boolean | null };

export type PatientQueryVariables = Exact<{
  id: Scalars['String'];
  patientIdType: Scalars['String'];
}>;


export type PatientQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null, patientId: string, patientIdType: string } | null };

export type PatientUploadPhotoQueryVariables = Exact<{
  id: Scalars['String'];
  patientIdType: Scalars['String'];
}>;


export type PatientUploadPhotoQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', photoUploadUrl?: string | null } | null };

export type PatientUpdateMutationVariables = Exact<{
  patient: PatientUpdate;
}>;


export type PatientUpdateMutation = { __typename?: 'Mutation', updatePatient?: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null } | null };

export type RecordingsByCriteriaQueryVariables = Exact<{
  criteria: RecordingQueryInput;
}>;


export type RecordingsByCriteriaQuery = { __typename?: 'Query', recordings: Array<{ __typename?: 'Recording', recordingId?: string | null, recordedAt?: any | null, user?: string | null, patientId?: string | null, status?: string | null, assignedTo?: string | null, originalTranscription?: string | null, correctedTranscription?: string | null, altTranscription?: string | null, notes?: string | null } | null> };

export type RecordingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecordingQuery = { __typename?: 'Query', recording: { __typename?: 'Recording', downloadUrl?: string | null } };

export type RecordingsByIdQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type RecordingsByIdQuery = { __typename?: 'Query', recordingsById: Array<{ __typename?: 'Recording', downloadUrl?: string | null } | null> };

export type SiteNotesQueryVariables = Exact<{
  criteria: SiteNoteQueryInput;
}>;


export type SiteNotesQuery = { __typename?: 'Query', siteNotes: Array<{ __typename?: 'SiteNote', filename?: string | null, noteId?: string | null, user?: string | null, createdAt?: any | null } | null> };

export type SiteNotesByIdQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type SiteNotesByIdQuery = { __typename?: 'Query', siteNotesById: Array<{ __typename?: 'SiteNote', downloadUrl?: string | null } | null> };

export type UpdateRecordingStatusMutationVariables = Exact<{
  keys: RecordingKeys;
  update: RecordingStatusUpdate;
}>;


export type UpdateRecordingStatusMutation = { __typename?: 'Mutation', updateRecordingStatus?: { __typename?: 'Recording', status?: string | null, assignedTo?: string | null } | null };

export type UpdateRecordingNotesMutationVariables = Exact<{
  keys: RecordingKeys;
  update: RecordingNotesUpdate;
}>;


export type UpdateRecordingNotesMutation = { __typename?: 'Mutation', updateRecordingNotes?: { __typename?: 'Recording', originalTranscription?: string | null, correctedTranscription?: string | null, altTranscription?: string | null, notes?: string | null } | null };

export type UserForSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserForSessionQuery = { __typename?: 'Query', userForSession?: { __typename?: 'WebUser', username: string, role: string } | null };

export type UsersForRoleQueryVariables = Exact<{
  role: Scalars['String'];
}>;


export type UsersForRoleQuery = { __typename?: 'Query', usersForRole?: Array<string | null> | null };


export const CreatePatientDocument = `
    mutation createPatient($patient: PatientCreation!) {
  createPatient(patient: $patient) {
    patientId
    patientIdType
  }
}
    `;
export const CreateRecordingDocument = `
    mutation createRecording($recording: RecordingInput!) {
  createRecording(recording: $recording) {
    uploadUrl
  }
}
    `;
export const CreateSiteNoteDocument = `
    mutation createSiteNote($siteNote: SiteNoteInput!) {
  createSiteNote(note: $siteNote) {
    uploadUrl
  }
}
    `;
export const CreateVisitDocument = `
    mutation createVisit($patientId: String!, $patientIdType: String!, $visitId: String!, $createdAt: Date!, $visit: VisitInput!, $vitals: VitalsInput!) {
  createVisit(
    patientId: $patientId
    patientIdType: $patientIdType
    visitId: $visitId
    createdAt: $createdAt
    visit: $visit
  ) {
    patientId
    patientIdType
    visitId
    chiefComplaint
  }
  createVitals(
    patientId: $patientId
    patientIdType: $patientIdType
    eventId: $visitId
    eventType: "V"
    createdAt: $createdAt
    vitals: $vitals
  ) {
    patientId
    patientIdType
    eventId
    eventType
    height
    weight
    temperature
    heartRate
    respiratoryRate
    bloodOxygen
    systolicBloodPressure
    diastolicBloodPressure
  }
}
    `;
export const CreateWebUserDocument = `
    mutation createWebUser($webUser: WebUserInput!) {
  createWebUser(webUser: $webUser) {
    username
  }
}
    `;
export const DeleteRecordingDocument = `
    mutation deleteRecording($recordingKeys: RecordingKeys!) {
  deleteRecording(recording: $recordingKeys)
}
    `;
export const LoginDocument = `
    mutation login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    username
    role
  }
}
    `;
export const LogoutDocument = `
    mutation logout {
  logout
}
    `;
export const PatientDocument = `
    query patient($id: String!, $patientIdType: String!) {
  patient(id: $id, patientIdType: $patientIdType) {
    firstName
    lastName
    patientId
    patientIdType
  }
}
    `;
export const PatientUploadPhotoDocument = `
    query patientUploadPhoto($id: String!, $patientIdType: String!) {
  patient(id: $id, patientIdType: $patientIdType) {
    photoUploadUrl
  }
}
    `;
export const PatientUpdateDocument = `
    mutation patientUpdate($patient: PatientUpdate!) {
  updatePatient(patient: $patient) {
    firstName
    lastName
  }
}
    `;
export const RecordingsByCriteriaDocument = `
    query recordingsByCriteria($criteria: RecordingQueryInput!) {
  recordings(criteria: $criteria) {
    recordingId
    recordedAt
    user
    patientId
    status
    assignedTo
    originalTranscription
    correctedTranscription
    altTranscription
    notes
  }
}
    `;
export const RecordingDocument = `
    query recording($id: String!) {
  recording(id: $id) {
    downloadUrl
  }
}
    `;
export const RecordingsByIdDocument = `
    query recordingsById($ids: [String]!) {
  recordingsById(ids: $ids) {
    downloadUrl
  }
}
    `;
export const SiteNotesDocument = `
    query siteNotes($criteria: SiteNoteQueryInput!) {
  siteNotes(criteria: $criteria) {
    filename
    noteId
    user
    createdAt
  }
}
    `;
export const SiteNotesByIdDocument = `
    query siteNotesById($ids: [String]!) {
  siteNotesById(ids: $ids) {
    downloadUrl
  }
}
    `;
export const UpdateRecordingStatusDocument = `
    mutation updateRecordingStatus($keys: RecordingKeys!, $update: RecordingStatusUpdate!) {
  updateRecordingStatus(recording: $keys, status: $update) {
    status
    assignedTo
  }
}
    `;
export const UpdateRecordingNotesDocument = `
    mutation updateRecordingNotes($keys: RecordingKeys!, $update: RecordingNotesUpdate!) {
  updateRecordingNotes(recording: $keys, updates: $update) {
    originalTranscription
    correctedTranscription
    altTranscription
    notes
  }
}
    `;
export const UserForSessionDocument = `
    query userForSession {
  userForSession {
    username
    role
  }
}
    `;
export const UsersForRoleDocument = `
    query usersForRole($role: String!) {
  usersForRole(role: $role)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createPatient: build.mutation<CreatePatientMutation, CreatePatientMutationVariables>({
      query: (variables) => ({ document: CreatePatientDocument, variables })
    }),
    createRecording: build.mutation<CreateRecordingMutation, CreateRecordingMutationVariables>({
      query: (variables) => ({ document: CreateRecordingDocument, variables })
    }),
    createSiteNote: build.mutation<CreateSiteNoteMutation, CreateSiteNoteMutationVariables>({
      query: (variables) => ({ document: CreateSiteNoteDocument, variables })
    }),
    createVisit: build.mutation<CreateVisitMutation, CreateVisitMutationVariables>({
      query: (variables) => ({ document: CreateVisitDocument, variables })
    }),
    createWebUser: build.mutation<CreateWebUserMutation, CreateWebUserMutationVariables>({
      query: (variables) => ({ document: CreateWebUserDocument, variables })
    }),
    deleteRecording: build.mutation<DeleteRecordingMutation, DeleteRecordingMutationVariables>({
      query: (variables) => ({ document: DeleteRecordingDocument, variables })
    }),
    login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables) => ({ document: LoginDocument, variables })
    }),
    logout: build.mutation<LogoutMutation, LogoutMutationVariables | void>({
      query: (variables) => ({ document: LogoutDocument, variables })
    }),
    patient: build.query<PatientQuery, PatientQueryVariables>({
      query: (variables) => ({ document: PatientDocument, variables })
    }),
    patientUploadPhoto: build.query<PatientUploadPhotoQuery, PatientUploadPhotoQueryVariables>({
      query: (variables) => ({ document: PatientUploadPhotoDocument, variables })
    }),
    patientUpdate: build.mutation<PatientUpdateMutation, PatientUpdateMutationVariables>({
      query: (variables) => ({ document: PatientUpdateDocument, variables })
    }),
    recordingsByCriteria: build.query<RecordingsByCriteriaQuery, RecordingsByCriteriaQueryVariables>({
      query: (variables) => ({ document: RecordingsByCriteriaDocument, variables })
    }),
    recording: build.query<RecordingQuery, RecordingQueryVariables>({
      query: (variables) => ({ document: RecordingDocument, variables })
    }),
    recordingsById: build.query<RecordingsByIdQuery, RecordingsByIdQueryVariables>({
      query: (variables) => ({ document: RecordingsByIdDocument, variables })
    }),
    siteNotes: build.query<SiteNotesQuery, SiteNotesQueryVariables>({
      query: (variables) => ({ document: SiteNotesDocument, variables })
    }),
    siteNotesById: build.query<SiteNotesByIdQuery, SiteNotesByIdQueryVariables>({
      query: (variables) => ({ document: SiteNotesByIdDocument, variables })
    }),
    updateRecordingStatus: build.mutation<UpdateRecordingStatusMutation, UpdateRecordingStatusMutationVariables>({
      query: (variables) => ({ document: UpdateRecordingStatusDocument, variables })
    }),
    updateRecordingNotes: build.mutation<UpdateRecordingNotesMutation, UpdateRecordingNotesMutationVariables>({
      query: (variables) => ({ document: UpdateRecordingNotesDocument, variables })
    }),
    userForSession: build.query<UserForSessionQuery, UserForSessionQueryVariables | void>({
      query: (variables) => ({ document: UserForSessionDocument, variables })
    }),
    usersForRole: build.query<UsersForRoleQuery, UsersForRoleQueryVariables>({
      query: (variables) => ({ document: UsersForRoleDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreatePatientMutation, useCreateRecordingMutation, useCreateSiteNoteMutation, useCreateVisitMutation, useCreateWebUserMutation, useDeleteRecordingMutation, useLoginMutation, useLogoutMutation, usePatientQuery, useLazyPatientQuery, usePatientUploadPhotoQuery, useLazyPatientUploadPhotoQuery, usePatientUpdateMutation, useRecordingsByCriteriaQuery, useLazyRecordingsByCriteriaQuery, useRecordingQuery, useLazyRecordingQuery, useRecordingsByIdQuery, useLazyRecordingsByIdQuery, useSiteNotesQuery, useLazySiteNotesQuery, useSiteNotesByIdQuery, useLazySiteNotesByIdQuery, useUpdateRecordingStatusMutation, useUpdateRecordingNotesMutation, useUserForSessionQuery, useLazyUserForSessionQuery, useUsersForRoleQuery, useLazyUsersForRoleQuery } = injectedRtkApi;

