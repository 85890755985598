import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

function CenteredForm({ children }: { children: React.ReactNode }) {
  return (
    <Box width="100%" height="100%">
      <Stack spacing={2} width="250px" mt="50px" mx="auto">
        {children}
      </Stack>
    </Box>
  );
}

export default CenteredForm;
