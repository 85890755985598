import * as R from "ramda";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { nullaryBool } from "shared-utils";
import MultiSelectFromMenu from "../../components/MultiSelectFromMenu";
import { useCreatePatientMutation } from "../../hooks/apiHooks/patientMutationHooks";
import { PatientCreation } from "../../graphql/generated";
import AmchurDialog from "../../components/AmchurDialog";
import CenteredForm from "../../components/CenteredForm";
import DefinePasswordFieldSet from "../../components/DefinePasswordFieldSet";
import PatientIdField from "../../components/PatientIdField";
import useDefinePasswordState, {
  type DefinePasswordState,
} from "../../hooks/useDefinePasswordState";
import { textChangeHandler } from "../../utils/eventUtils";

function CreatePatientRoute() {
  const [createPatientOnServer] = useCreatePatientMutation();

  const createPatient = (
    patientId: string,
    password: string,
    firstName: string,
    lastName: string,
    allergies: string[]
  ) => {
    const args: PatientCreation = {
      patientId,
      patientIdType: "PKCNI",
      password,
      firstName,
      lastName,
      allergies,
    };
    return createPatientOnServer({ patient: args }).then(() => {});
  };

  return <CreatePatientStateView createPatient={createPatient} />;
}

type PatientCreationCallback = (
  patientId: string,
  password: string,
  firstName: string,
  lastName: string,
  allergies: string[]
) => Promise<void>;

interface CreatePatientStateViewProps {
  createPatient: PatientCreationCallback;
}

function CreatePatientStateView({
  createPatient,
}: CreatePatientStateViewProps) {
  const {
    acceptablePassword: password,
    definePasswordState,
    clearPassword,
  } = useDefinePasswordState();
  const [patientId, setPatientId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [allergies, setAllergies] = useState<string[]>([]);
  const [showingDialog, setShowingDialog] = useState(false);
  const [showDialog, hideDialog] = nullaryBool(setShowingDialog);

  const updateAllergies = R.compose(
    setAllergies,
    R.sort((a, b) => a.localeCompare(b))
  );

  const clearAll = () => {
    setPatientId("");
    setFirstName("");
    setLastName("");
    setAllergies([]);
    clearPassword();
  };

  const onSave = () => {
    createPatient(patientId, password, firstName, lastName, allergies)
      .then(() => {
        showDialog();
        clearAll();
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <>
      <CreatePatientUiView
        patientId={patientId}
        setPatientId={setPatientId}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        allergies={allergies}
        setAllergies={updateAllergies}
        password={password}
        definePasswordState={definePasswordState}
        onSave={onSave}
      />
      <CreatePatientSuccessDialog open={showingDialog} onClose={hideDialog} />
    </>
  );
}

interface CreatePatientUiViewProps {
  patientId: string;
  setPatientId: (patientId: string) => void;
  firstName: string;
  setFirstName: (firstName: string) => void;
  lastName: string;
  setLastName: (lastName: string) => void;
  allergies: string[];
  setAllergies: (allergies: string[]) => void;
  password: string;
  definePasswordState: DefinePasswordState;
  onSave: () => void;
}

function CreatePatientUiView({
  patientId,
  setPatientId,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  allergies,
  setAllergies,
  password,
  definePasswordState,
  onSave,
}: CreatePatientUiViewProps) {
  const intl = useIntl();
  const buttonEnabled = patientId.length > 0 && password && password.length > 0;

  return (
    <CenteredForm>
      <PatientIdField
        patientId={patientId}
        setPatientId={setPatientId}
        required={true}
        disabled={false}
      />
      <DefinePasswordFieldSet
        username=""
        definePasswordState={definePasswordState}
      />
      <TextField
        label={intl.formatMessage({ id: "createPatient.firstName" })}
        variant="filled"
        value={firstName}
        onChange={textChangeHandler(setFirstName)}
      />
      <TextField
        label={intl.formatMessage({ id: "createPatient.lastName" })}
        variant="filled"
        value={lastName}
        onChange={textChangeHandler(setLastName)}
      />
      <MultiSelectFromMenu
        value={allergies}
        setValue={setAllergies}
        options={["Penicillin", "Sulfa", "Tetracycline", "Codeine", "Quinine"]}
        label="allergies"
        id="allerg"
      />
      <Box>
        <Button
          variant="contained"
          disabled={!buttonEnabled}
          data-testid="submit-button"
          onClick={onSave}
        >
          <FormattedMessage id="createPatient.save" />
        </Button>
      </Box>
    </CenteredForm>
  );
}

interface CreatePatientSuccessDialogProps {
  open: boolean;
  onClose: () => void;
}
function CreatePatientSuccessDialog({
  open,
  onClose,
}: CreatePatientSuccessDialogProps) {
  return (
    <AmchurDialog
      isOpen={open}
      onCloseRequested={onClose}
      titleColor="success"
      title="Create Patient"
      contentText="This patient is now ready to use the Android app."
    />
  );
}

export default CreatePatientRoute;
