import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AmchurDialog from "../../components/AmchurDialog";
import PatientIdField from "../../components/PatientIdField";
import {
  useIntStringState,
  useFloatStringState,
} from "../../hooks/useIntStringState";
import { textChangeHandler } from "../../utils/eventUtils";

interface VitalsFormProps {
  patientId: string;
  setPatientId: (patientId: string) => void;
  height: string;
  setHeight: (height: string) => void;
  weight: string;
  setWeight: (weight: string) => void;
  systolic: string;
  setSystolic: (systolic: string) => void;
  diastolic: string;
  setDiastolic: (diastolic: string) => void;
  heartRate: string;
  setHeartRate: (heartRate: string) => void;
  respiratoryRate: string;
  setRespiratoryRate: (respiratoryRate: string) => void;
  bloodOxygen: string;
  setBloodOxygen: (bloodOxygen: string) => void;
  temp: string;
  setTemp: (temp: string) => void;
}

function VitalsForm({
  patientId,
  setPatientId,
  height,
  setHeight,
  weight,
  setWeight,
  systolic,
  setSystolic,
  diastolic,
  setDiastolic,
  heartRate,
  setHeartRate,
  respiratoryRate,
  setRespiratoryRate,
  bloodOxygen,
  setBloodOxygen,
  temp,
  setTemp,
}: VitalsFormProps) {
  const intl = useIntl();

  return (
    <Stack>
      <PatientIdField
        patientId={patientId}
        setPatientId={setPatientId}
        required={true}
        disabled={false}
      />
      <Typography variant="h6">
        <FormattedMessage id="vitals.height" />
      </Typography>
      <TextField
        label={intl.formatMessage({ id: "vitals.height-unit" })}
        inputProps={{ "data-testid": "height" }}
        size="small"
        variant="filled"
        value={height}
        onChange={textChangeHandler(setHeight)}
      />
      <Typography variant="h6">
        <FormattedMessage id="vitals.weight" />
      </Typography>
      <TextField
        label={intl.formatMessage({ id: "vitals.weight-unit" })}
        inputProps={{ "data-testid": "weight" }}
        size="small"
        variant="filled"
        value={weight}
        onChange={textChangeHandler(setWeight)}
      />

      <Typography variant="h6">
        <FormattedMessage id="vitals.temperature" />
      </Typography>
      <TextField
        label={intl.formatMessage({ id: "vitals.temperature-unit" })}
        inputProps={{ "data-testid": "temp" }}
        size="small"
        variant="filled"
        value={temp}
        onChange={textChangeHandler(setTemp)}
      />

      <Typography variant="h6">
        <FormattedMessage id="vitals.blood-pressure" />
      </Typography>
      <Stack direction="row" alignItems="center">
        <TextField
          label={intl.formatMessage({ id: "vitals.systolic" })}
          inputProps={{ "data-testid": "systolic" }}
          size="small"
          variant="filled"
          value={systolic}
          onChange={textChangeHandler(setSystolic)}
        />
        <Typography variant="h6" mx="1em">
          /
        </Typography>
        <TextField
          label={intl.formatMessage({ id: "vitals.diastolic" })}
          inputProps={{ "data-testid": "diastolic" }}
          size="small"
          variant="filled"
          value={diastolic}
          onChange={textChangeHandler(setDiastolic)}
        />
      </Stack>

      <Typography variant="h6">
        <FormattedMessage id="vitals.heartRate" />
      </Typography>
      <TextField
        label={intl.formatMessage({ id: "vitals.heartRate-unit" })}
        inputProps={{ "data-testid": "heartRate" }}
        size="small"
        variant="filled"
        value={heartRate}
        onChange={textChangeHandler(setHeartRate)}
      />
      <Typography variant="h6">
        <FormattedMessage id="vitals.respiratoryRate" />
      </Typography>
      <TextField
        label={intl.formatMessage({ id: "vitals.respiratoryRate-unit" })}
        inputProps={{ "data-testid": "respiratoryRate" }}
        size="small"
        variant="filled"
        value={respiratoryRate}
        onChange={textChangeHandler(setRespiratoryRate)}
      />
      <Typography variant="h6">
        <FormattedMessage id="vitals.bloodOxygen" />
      </Typography>
      <TextField
        label={intl.formatMessage({ id: "vitals.bloodOxygen-unit" })}
        inputProps={{ "data-testid": "bloodOxygen" }}
        size="small"
        variant="filled"
        value={bloodOxygen}
        onChange={textChangeHandler(setBloodOxygen)}
      />
    </Stack>
  );
}

type OnCreateVitals = (
  patientId: string,
  height: number,
  weight: number,
  systolicBloodPressure: number,
  diastolicBloodPressure: number,
  heartRate: number,
  respiratoryRate: number,
  bloodOxygen: number,
  temperature: number
) => Promise<boolean>;

interface VitalsDialogProps {
  open: boolean;
  requestClose: () => void;
  onCreateVitals: OnCreateVitals;
}

export default function VitalsDialog({
  open,
  requestClose,
  onCreateVitals,
}: VitalsDialogProps) {
  const intl = useIntl();

  const [patientId, setPatientId] = useState("");
  const [height, heightString, setHeight] = useIntStringState(0);
  const [weight, weightString, setWeight] = useIntStringState(0);
  const [systolic, systolicString, setSystolic] = useIntStringState(0);
  const [diastolic, diastolicString, setDiastolic] = useIntStringState(0);
  const [heartRate, heartRateString, setHeartRate] = useIntStringState(0);
  const [respiratoryRate, respiratoryRateString, setRespiratoryRate] =
    useIntStringState(0);
  const [bloodOxygen, bloodOxygenString, setBloodOxygen] = useIntStringState(0);
  const [temp, tempString, setTemp] = useFloatStringState(0);

  // we can add more fields here if we want them to also be required
  // just be sure to update the test as well.
  const isButtonEnabled =
    patientId.length > 0 && [height, weight].every((s) => s > 0);

  const onCreate = () => {
    return onCreateVitals(
      patientId,
      height,
      weight,
      systolic,
      diastolic,
      heartRate,
      respiratoryRate,
      bloodOxygen,
      temp
    );
  };
  return (
    <AmchurDialog
      maxWidth="lg"
      fullWidth={true}
      isOpen={open}
      onCloseRequested={requestClose}
      title={intl.formatMessage({ id: "vitals.title" })}
      titleColor="secondary"
      titleVariant="h4"
      onOk={onCreate}
      disabled={!isButtonEnabled}
    >
      <VitalsForm
        patientId={patientId}
        setPatientId={setPatientId}
        height={heightString}
        setHeight={setHeight}
        weight={weightString}
        setWeight={setWeight}
        systolic={systolicString}
        setSystolic={setSystolic}
        diastolic={diastolicString}
        setDiastolic={setDiastolic}
        heartRate={heartRateString}
        setHeartRate={setHeartRate}
        respiratoryRate={respiratoryRateString}
        setRespiratoryRate={setRespiratoryRate}
        bloodOxygen={bloodOxygenString}
        setBloodOxygen={setBloodOxygen}
        temp={tempString}
        setTemp={setTemp}
      />
    </AmchurDialog>
  );
}
