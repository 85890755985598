import { DateTime } from "luxon";
import {
  type VisitInput,
  useCreateVisitMutation as useCreateVisitMutationInternal,
  type VitalsInput,
  type Visit,
  type Vitals,
} from "../../graphql/generated";

type DataObject = {
  createVisit?: Visit;
  createVitals?: Vitals;
};

interface ResultObject {
  data: DataObject;
}

interface CreateVisitArgs {
  patientId: string;
  patientIdType: string;
  visitId: string;
  createdAt: DateTime;
  visit: VisitInput;
  vitals: VitalsInput;
}
type MutationArgs = Parameters<typeof useCreateVisitMutationInternal>[0];
export function useCreateVisitMutation(args: MutationArgs = {}) {
  const [createVisitInternal, createVisitResult] =
    useCreateVisitMutationInternal(args);

  const createVisit = async (args: CreateVisitArgs) => {
    const result = (await createVisitInternal(args)) as unknown as ResultObject;
    console.log(`result in create visit hook is ${JSON.stringify(result)}`);
    return {
      visit: result.data?.createVisit,
      vitals: result.data?.createVitals,
    };
  };

  return { createVisit, createVisitResult };
}
