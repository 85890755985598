import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../graphql/generated";
import currentUserReducer from "./currentUserSlice";
import initialRequestReducer from "./initialRequestSlice";
import visitReducer from "./visitSlice";
import recordingListReducer from "./recordingListSlice";

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  initialRequest: initialRequestReducer,
  visit: visitReducer,
  recordingList: recordingListReducer,
  [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
