export const patientQuery = /* GraphQL */ `
  query patient($id: String!, $patientIdType: String!) {
    patient(id: $id, patientIdType: $patientIdType) {
      firstName
      lastName
      patientId
      patientIdType
    }
  }
`;

export const patientUploadPhotoQuery = /* GraphQL */ `
  query patientUploadPhoto($id: String!, $patientIdType: String!) {
    patient(id: $id, patientIdType: $patientIdType) {
      photoUploadUrl
    }
  }
`;
