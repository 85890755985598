import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { type RootState } from "../../state/reducers";
import { LoginState } from "../../state/currentUserSlice";
import { performLogin } from "../../middleware/authMiddleware";
import { AppDispatch } from "../../state/store";
import { textChangeHandler } from "../../utils/eventUtils";
import useCurrentRole from "../../hooks/useCurrentRole";

type LoginCallback = (u: string, p: string) => void;

interface LoginUiViewProps {
  loginInProgress: boolean;
  onLogin: LoginCallback;
  username: string;
  setUsername: (u: string) => void;
  password: string;
  setPassword: (p: string) => void;
}

function LoginUiView({
  loginInProgress,
  onLogin,
  username,
  setUsername,
  password,
  setPassword,
}: LoginUiViewProps) {
  const onClick = () => {
    onLogin(username, password);
  };
  return (
    <Box width="100%" height="100%">
      <Stack spacing={2} width="250px" mt="50px" mx="auto">
        <TextField
          required
          id="filled-required"
          label="Username"
          variant="filled"
          value={username}
          disabled={loginInProgress}
          onChange={textChangeHandler(setUsername)}
        />
        <TextField
          required
          id="filled-required"
          label="Password"
          variant="filled"
          type="password"
          autoComplete="current-password"
          value={password}
          disabled={loginInProgress}
          onChange={textChangeHandler(setPassword)}
        />
        <Box>
          <Button
            variant="contained"
            onClick={onClick}
            disabled={loginInProgress}
          >
            Login
          </Button>
        </Box>
        {loginInProgress && <CircularProgress />}
      </Stack>
    </Box>
  );
}

const landingPageForRole: { [key: string]: string } = {
  user: "/record",
  trn: "/recordings",
  su: "/recordings",
};

export default function LoginRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const role = useCurrentRole();
  const intl = useIntl();

  const loginState = useSelector(
    (state: RootState) => state.currentUser.loginState
  );

  const handleLoginFailed = () => {
    setPassword("");
    enqueueSnackbar(intl.formatMessage({ id: "login.failed" }), {
      variant: "error",
    });
  };

  const doLogin = async (username: string, password: string) => {
    const result = await dispatch(performLogin({ username, password }));
    if (performLogin.fulfilled.match(result)) {
    }
    if (result.payload) {
      const landing = landingPageForRole[role] || "/recordings";
      navigate(landing);
    } else {
      handleLoginFailed();
    }
  };

  return (
    <LoginUiView
      loginInProgress={loginState === LoginState.LoggingIn}
      onLogin={(username, password) => {
        void doLogin(username, password);
      }}
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
    />
  );
}
