import * as R from "ramda";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { SxProps, Theme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { multiSelectHandler } from "../utils/eventUtils";

interface MultiSelectFromMenuProps {
  value: string[];
  setValue: (v: string[]) => void;
  options: string[];
  label: string;
  id?: string;
  sx?: SxProps<Theme>;
}
function MultiSelectFromMenu({
  value,
  setValue,
  options,
  label,
  id,
  sx,
}: MultiSelectFromMenuProps) {
  const toggleSelection = (option: string[] | string) => {
    if (Array.isArray(option)) {
      console.log("set value");
      setValue(option);
    }
    console.log(`toggleSelection: option=${JSON.stringify(option)}`);
    // if(value.includes(option)) {
    //   setValue(value.filter((v) => v !== option));
    // } else {
    //   setValue([...value, option]);
    // }
  };
  const onChange2 = R.compose(toggleSelection, multiSelectHandler);

  const sxSetting = sx || { width: 300, marginTop: "1em" };
  const labelId = `${id || label}-select-label`;
  return (
    <FormControl sx={sxSetting} variant="filled">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={value}
        onChange={onChange2}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                color="secondary"
                variant="outlined"
              />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            //style={getStyles(name, personName, theme)}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectFromMenu;
