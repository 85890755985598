/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation createPatient($patient: PatientCreation!) {\n    createPatient(patient: $patient) {\n      patientId\n      patientIdType\n    }\n  }\n": types.CreatePatientDocument,
    "\n  mutation createRecording($recording: RecordingInput!) {\n    createRecording(recording: $recording) {\n      uploadUrl\n    }\n  }\n": types.CreateRecordingDocument,
    "\n  mutation createSiteNote($siteNote: SiteNoteInput!) {\n    createSiteNote(note: $siteNote) {\n      uploadUrl\n    }\n  }\n": types.CreateSiteNoteDocument,
    "\n  mutation createVisit(\n    $patientId: String!\n    $patientIdType: String!\n    $visitId: String!\n    $createdAt: Date!\n    $visit: VisitInput!\n    $vitals: VitalsInput!\n  ) {\n    createVisit(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      visitId: $visitId\n      createdAt: $createdAt\n      visit: $visit\n    ) {\n      patientId\n      patientIdType\n      visitId\n      chiefComplaint\n    }\n    createVitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventId: $visitId\n      eventType: \"V\"\n      createdAt: $createdAt\n      vitals: $vitals\n    ) {\n      patientId\n      patientIdType\n      eventId\n      eventType\n      height\n      weight\n      temperature\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      systolicBloodPressure\n      diastolicBloodPressure\n    }\n  }\n": types.CreateVisitDocument,
    "\n  mutation createWebUser($webUser: WebUserInput!) {\n    createWebUser(webUser: $webUser) {\n      username\n    }\n  }\n": types.CreateWebUserDocument,
    "\n  mutation deleteRecording($recordingKeys: RecordingKeys!) {\n    deleteRecording(recording: $recordingKeys)\n  }\n": types.DeleteRecordingDocument,
    "\n  mutation login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      username\n      role\n    }\n  }\n": types.LoginDocument,
    "\n  mutation logout {\n    logout\n  }\n": types.LogoutDocument,
    "\n  query patient($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      firstName\n      lastName\n      patientId\n      patientIdType\n    }\n  }\n": types.PatientDocument,
    "\n  query patientUploadPhoto($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      photoUploadUrl\n    }\n  }\n": types.PatientUploadPhotoDocument,
    "\n  mutation patientUpdate($patient: PatientUpdate!) {\n    updatePatient(patient: $patient) {\n      firstName\n      lastName\n    }\n  }\n": types.PatientUpdateDocument,
    "\n  query recordingsByCriteria($criteria: RecordingQueryInput!) {\n    recordings(criteria: $criteria) {\n      recordingId\n      recordedAt\n      user\n      patientId\n      status\n      assignedTo\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n": types.RecordingsByCriteriaDocument,
    "\n  query recording($id: String!) {\n    recording(id: $id) {\n      downloadUrl\n    }\n  }\n": types.RecordingDocument,
    "\n  query recordingsById($ids: [String]!) {\n    recordingsById(ids: $ids) {\n      downloadUrl\n    }\n  }\n": types.RecordingsByIdDocument,
    "\n  query siteNotes($criteria: SiteNoteQueryInput!) {\n    siteNotes(criteria: $criteria) {\n      filename\n      noteId\n      user\n      createdAt\n    }\n  }\n": types.SiteNotesDocument,
    "\n  query siteNotesById($ids: [String]!) {\n    siteNotesById(ids: $ids) {\n      downloadUrl\n    }\n  }\n": types.SiteNotesByIdDocument,
    "\n  mutation updateRecordingStatus(\n    $keys: RecordingKeys!\n    $update: RecordingStatusUpdate!\n  ) {\n    updateRecordingStatus(recording: $keys, status: $update) {\n      status\n      assignedTo\n    }\n  }\n": types.UpdateRecordingStatusDocument,
    "\n  mutation updateRecordingNotes(\n    $keys: RecordingKeys!\n    $update: RecordingNotesUpdate!\n  ) {\n    updateRecordingNotes(recording: $keys, updates: $update) {\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n": types.UpdateRecordingNotesDocument,
    "\n  query userForSession {\n    userForSession {\n      username\n      role\n    }\n  }\n": types.UserForSessionDocument,
    "\n  query usersForRole($role: String!) {\n    usersForRole(role: $role)\n  }\n": types.UsersForRoleDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createPatient($patient: PatientCreation!) {\n    createPatient(patient: $patient) {\n      patientId\n      patientIdType\n    }\n  }\n"): (typeof documents)["\n  mutation createPatient($patient: PatientCreation!) {\n    createPatient(patient: $patient) {\n      patientId\n      patientIdType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createRecording($recording: RecordingInput!) {\n    createRecording(recording: $recording) {\n      uploadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation createRecording($recording: RecordingInput!) {\n    createRecording(recording: $recording) {\n      uploadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createSiteNote($siteNote: SiteNoteInput!) {\n    createSiteNote(note: $siteNote) {\n      uploadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation createSiteNote($siteNote: SiteNoteInput!) {\n    createSiteNote(note: $siteNote) {\n      uploadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createVisit(\n    $patientId: String!\n    $patientIdType: String!\n    $visitId: String!\n    $createdAt: Date!\n    $visit: VisitInput!\n    $vitals: VitalsInput!\n  ) {\n    createVisit(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      visitId: $visitId\n      createdAt: $createdAt\n      visit: $visit\n    ) {\n      patientId\n      patientIdType\n      visitId\n      chiefComplaint\n    }\n    createVitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventId: $visitId\n      eventType: \"V\"\n      createdAt: $createdAt\n      vitals: $vitals\n    ) {\n      patientId\n      patientIdType\n      eventId\n      eventType\n      height\n      weight\n      temperature\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      systolicBloodPressure\n      diastolicBloodPressure\n    }\n  }\n"): (typeof documents)["\n  mutation createVisit(\n    $patientId: String!\n    $patientIdType: String!\n    $visitId: String!\n    $createdAt: Date!\n    $visit: VisitInput!\n    $vitals: VitalsInput!\n  ) {\n    createVisit(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      visitId: $visitId\n      createdAt: $createdAt\n      visit: $visit\n    ) {\n      patientId\n      patientIdType\n      visitId\n      chiefComplaint\n    }\n    createVitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventId: $visitId\n      eventType: \"V\"\n      createdAt: $createdAt\n      vitals: $vitals\n    ) {\n      patientId\n      patientIdType\n      eventId\n      eventType\n      height\n      weight\n      temperature\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      systolicBloodPressure\n      diastolicBloodPressure\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWebUser($webUser: WebUserInput!) {\n    createWebUser(webUser: $webUser) {\n      username\n    }\n  }\n"): (typeof documents)["\n  mutation createWebUser($webUser: WebUserInput!) {\n    createWebUser(webUser: $webUser) {\n      username\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteRecording($recordingKeys: RecordingKeys!) {\n    deleteRecording(recording: $recordingKeys)\n  }\n"): (typeof documents)["\n  mutation deleteRecording($recordingKeys: RecordingKeys!) {\n    deleteRecording(recording: $recordingKeys)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      username\n      role\n    }\n  }\n"): (typeof documents)["\n  mutation login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      username\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation logout {\n    logout\n  }\n"): (typeof documents)["\n  mutation logout {\n    logout\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patient($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      firstName\n      lastName\n      patientId\n      patientIdType\n    }\n  }\n"): (typeof documents)["\n  query patient($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      firstName\n      lastName\n      patientId\n      patientIdType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patientUploadPhoto($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      photoUploadUrl\n    }\n  }\n"): (typeof documents)["\n  query patientUploadPhoto($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      photoUploadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation patientUpdate($patient: PatientUpdate!) {\n    updatePatient(patient: $patient) {\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  mutation patientUpdate($patient: PatientUpdate!) {\n    updatePatient(patient: $patient) {\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recordingsByCriteria($criteria: RecordingQueryInput!) {\n    recordings(criteria: $criteria) {\n      recordingId\n      recordedAt\n      user\n      patientId\n      status\n      assignedTo\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"): (typeof documents)["\n  query recordingsByCriteria($criteria: RecordingQueryInput!) {\n    recordings(criteria: $criteria) {\n      recordingId\n      recordedAt\n      user\n      patientId\n      status\n      assignedTo\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recording($id: String!) {\n    recording(id: $id) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query recording($id: String!) {\n    recording(id: $id) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recordingsById($ids: [String]!) {\n    recordingsById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query recordingsById($ids: [String]!) {\n    recordingsById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query siteNotes($criteria: SiteNoteQueryInput!) {\n    siteNotes(criteria: $criteria) {\n      filename\n      noteId\n      user\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query siteNotes($criteria: SiteNoteQueryInput!) {\n    siteNotes(criteria: $criteria) {\n      filename\n      noteId\n      user\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query siteNotesById($ids: [String]!) {\n    siteNotesById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query siteNotesById($ids: [String]!) {\n    siteNotesById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateRecordingStatus(\n    $keys: RecordingKeys!\n    $update: RecordingStatusUpdate!\n  ) {\n    updateRecordingStatus(recording: $keys, status: $update) {\n      status\n      assignedTo\n    }\n  }\n"): (typeof documents)["\n  mutation updateRecordingStatus(\n    $keys: RecordingKeys!\n    $update: RecordingStatusUpdate!\n  ) {\n    updateRecordingStatus(recording: $keys, status: $update) {\n      status\n      assignedTo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateRecordingNotes(\n    $keys: RecordingKeys!\n    $update: RecordingNotesUpdate!\n  ) {\n    updateRecordingNotes(recording: $keys, updates: $update) {\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"): (typeof documents)["\n  mutation updateRecordingNotes(\n    $keys: RecordingKeys!\n    $update: RecordingNotesUpdate!\n  ) {\n    updateRecordingNotes(recording: $keys, updates: $update) {\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userForSession {\n    userForSession {\n      username\n      role\n    }\n  }\n"): (typeof documents)["\n  query userForSession {\n    userForSession {\n      username\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query usersForRole($role: String!) {\n    usersForRole(role: $role)\n  }\n"): (typeof documents)["\n  query usersForRole($role: String!) {\n    usersForRole(role: $role)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;