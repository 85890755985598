import { useState } from "react";

// manages an integer state that is easily converted to/from a string
// for use with a text field.
export function useIntStringState(
  initialValue: number,
  allowNegative = false
): [number, string, (newValue: string) => void] {
  const [value, setValue] = useState(initialValue);

  const stringValue = value === 0 ? "" : value.toString();

  const isValid = (v: number) => !isNaN(v) && (v >= 0 || allowNegative);
  const setStringValue = (newValue: string) => {
    const v = newValue === "" ? 0 : parseInt(newValue, 10);
    if (isValid(v)) {
      setValue(v);
    }
  };
  return [value, stringValue, setStringValue];
}

// manages a float state that is easily converted to/from a string
// for use with a text field.

export function useFloatStringState(
  initialValue: number,
  allowNegative = false
): [number, string, (newValue: string) => void] {
  const [value, setValue] = useState(initialValue);
  const [hasDotSuffix, setHasDotSuffix] = useState(false);

  const numericStringValue = value === 0 ? "" : value.toString();
  const stringValue = `${numericStringValue}${hasDotSuffix ? "." : ""}`;

  const isValid = (v: number) => !isNaN(v) && (v >= 0 || allowNegative);
  const setStringValue = (newValue: string) => {
    const v = newValue === "" ? 0 : parseFloat(newValue);
    if (isValid(v)) {
      setValue(v);
      const dotSuffix =
        newValue.endsWith(".") && stringValue.indexOf(".") === -1;
      if (dotSuffix !== hasDotSuffix) {
        setHasDotSuffix(dotSuffix);
      }
    }
  };

  return [value, stringValue, setStringValue];
}
