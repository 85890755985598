interface RecordingTools {
  mediaRecorder: MediaRecorder | null;
  mediaStream: MediaStream | null;
  //cancelled: boolean;
}

type StateCallback = (active: boolean) => void;
type DataCallback = (data: string) => Promise<void>;
type FailureCallback = (error: Error) => void;

interface Recorder {
  start(): Promise<void>;
  stop(): void;
  cancel(): void;
}

class RealRecorder implements Recorder {
  mediaRecorder: MediaRecorder | null;
  mediaStream: MediaStream | null;
  cancelled: boolean;
  onStateChange: StateCallback;
  onDataReceived: DataCallback;
  onFailure: FailureCallback;

  constructor(
    onStateChange: StateCallback,
    onDataReceived: DataCallback,
    onFailure: FailureCallback
  ) {
    this.mediaRecorder = null;
    this.mediaStream = null;
    this.cancelled = false;
    this.onStateChange = onStateChange;
    this.onDataReceived = onDataReceived;
    this.onFailure = onFailure;
  }

  async start(): Promise<void> {
    const mediaTools = await createMediaRecorder();
    this.mediaRecorder = mediaTools.mediaRecorder;
    this.mediaStream = mediaTools.mediaStream;

    if (this.mediaRecorder != null) {
      this.onStateChange(true);
      const data = await readDatafromRecorder(this.mediaRecorder);
      if (this.cancelled) {
        return;
      }
      if (data == null) {
        this.onFailure(new Error("No data received"));
      }
      await this.onDataReceived(data as string);
    }
  }

  stop() {
    this.onStateChange(false);
    this.mediaRecorder?.stop();
    this.mediaStream?.getAudioTracks()[0].stop();
  }
  cancel() {
    this.cancelled = true;
    this.stop();
  }
}
async function createMediaRecorder(): Promise<RecordingTools> {
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });
  const mediaRecorder = new MediaRecorder(mediaStream, {
    mimeType: "audio/webm",
    audioBitsPerSecond: 128000, // don't know if this is used.
  });
  return { mediaRecorder, mediaStream };
}

async function readDatafromRecorder(
  mediaRecorder: MediaRecorder
): Promise<string | ArrayBuffer | null> {
  mediaRecorder.start();
  return new Promise((resolve) => {
    mediaRecorder.ondataavailable = (e) => {
      const reader = new FileReader();
      reader.readAsDataURL(e.data);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    };
  });
}

function createRecorder(
  onStateChange: StateCallback,
  onDataReceived: DataCallback,
  onFailure: FailureCallback
) {
  return new RealRecorder(onStateChange, onDataReceived, onFailure);
}

export default createRecorder;
export type { Recorder, StateCallback, DataCallback, FailureCallback };
