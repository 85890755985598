import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00658F",
      light: "#75FDA1",
    },
    secondary: {
      main: "#006D37",
    },
    error: {
      main: "#BB1800",
    },
    secondaryContainer: {
      main: "#75FDA1",
    },
    primaryContainer: {
      main: "#C7E7FF",
    },
  },
});

theme.components = {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: "#C7E7FF",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        backgroundColor: "#333333",
        color: "#ffffff",
        textAlign: "left",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        backgroundColor: "#eeeeee",
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        backgroundColor: "#eeeeee",
      },
    },
  },
};

export default theme;
