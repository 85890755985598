import { useState } from "react";

function useMultiSelect(initial: string[] = []) {
  const [selectionList, setSelectionList] = useState<string[]>([]);

  const isSelected = (id: string) => selectionList.includes(id);

  const selectionWithChange = (selectionId: string, selected: boolean) => {
    if (selected) {
      if (isSelected(selectionId)) {
        return selectionList;
      } else {
        return [...selectionList, selectionId];
      }
    } else {
      return selectionList.filter((id) => id !== selectionId);
    }
  };

  const changeSelection = (selectionId: string, selected: boolean) =>
    setSelectionList(selectionWithChange(selectionId, selected));

  return { selectionList, isSelected, changeSelection, setSelectionList };
}

export default useMultiSelect;
